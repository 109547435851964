<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>
    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Payment Sales Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.payment.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table table-striped header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('payment_method')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Payment Type
                              </div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('total')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Total Price</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('tax')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Total Tax</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('credit')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Credit</div>
                            </div>
                          </th>
                          <th
                            class="md-table-head sticky"
                            v-if="checkTableColumnVisibility('total_amount')"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Total Amount
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="PaymentReportList.length"
                        :set="
                          ((grandTotalAmount = 0),
                          (grandTotalTax = 0),
                          (grandTotalPrice = 0))
                        "
                      >
                        <template v-for="(cash, index) in PaymentReportList">
                          <tr
                            :key="`cash_${index}`"
                            v-if="cash.total_amount != 0 || cash.total_tax != 0"
                            :set="
                              ((grandTotalAmount += cash.total_amount),
                              (grandTotalTax += cash.total_tax),
                              (grandTotalPrice += cash.total))
                            "
                          >
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ cash.created_datetime | timeStamp }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="
                                checkTableColumnVisibility('payment_method')
                              "
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.payment_method }}
                              </div>
                            </td>

                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('total')"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.total_amount }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('tax')"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.total_tax }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('total_amount')"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ cash.total }}
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template>
                          <tr
                            :key="`cash_${index}`"
                            class="grand_total_bg sticky_bottom"
                          >
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <strong>GRAND TOTAL</strong>
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="
                                checkTableColumnVisibility('payment_method')
                              "
                            >
                              <div
                                class="md-table-cell-container text-center"
                              ></div>
                            </td>

                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('total')"
                            >
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{ currencyCode }}
                                  {{
                                    parseFloat(grandTotalAmount).toFixed(2)
                                      | toCurrency
                                  }}</strong
                                >
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('tax')"
                            >
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{ currencyCode }}
                                  {{
                                    parseFloat(grandTotalTax).toFixed(2)
                                      | toCurrency
                                  }}</strong
                                >
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('total_amount')"
                            >
                              <div class="md-table-cell-container text-center">
                                <strong>
                                  {{ currencyCode }}
                                  {{
                                    parseFloat(grandTotalPrice).toFixed(2)
                                      | toCurrency
                                  }}</strong
                                >
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: { BackButton, ExportIcon, SvgIcon },
  data() {
    return {
      PaymentReportList: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      columnData: [
        { name: "Payment Type", value: "payment_method" },
        { name: "Total Price", value: "total" },
        { name: "Total Tax", value: "tax" },
        { name: "Total Amount", value: "total_amount" },
      ],
      columns: ["payment_method", "total", "tax", "total_amount"],
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }
      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Payment-Sale-Report-" + this.fileName;
      else this.fileName = "Payment-Sale-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/payment-type-sale-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.PaymentReportList = data;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "payment-type-sale-report-pdf";
      } else if (type == "excel") {
        link = "payment-type-sale-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },

    getTotalColspan() {
      let colspan = 0;
      if (this.checkTableColumnVisibility("timestamp")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("billno")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("cashier")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("category")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("item")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("bnotes")) {
        colspan += 1;
      }
      if (this.checkTableColumnVisibility("pnotes")) {
        colspan += 1;
      }
      return colspan;
    },
    checkTotal(PaymentReportList, index) {
      let total_amount = 0;
      let total_tax = 0;
      // if (PaymentReportList.length == 1) {
      //   return true;
      // }
      PaymentReportList.map((ele) => {
        if (ele.created_at == PaymentReportList[index].created_at) {
          total_amount = total_amount + parseInt(ele.total_amount);
          total_tax = total_tax + parseInt(ele.total_tax);
        }
      });
      if (total_amount || total_tax) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8; /* Set color opacity to 10% */
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
</style>
